<template>
    <div class="flex justify-bewteen items-center">

        <h1 class="text-lg">Page Not Found</h1>
        
    </div>
</template>

<script>

export default ({

    data() {
      return {

        }
    },


})
</script>

<style scoped>

</style>
